import Layout from '../components/layout';
import React from 'react';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div>
      <h2 className="bg-yellow inline-block my-8 p-3">
        Looks like this page is a ghost that got abducted by aliens...
      </h2>
    </div>
  </Layout>
);

export default NotFoundPage;
